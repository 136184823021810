import React from "react";
import {
  FaTwitter,
  FaFacebookF,
  FaYoutube,
  FaPaperPlane,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
import LogoIcon from "../images/chatImage/download.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-dark text-white p-5 py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3">
            <img
              src={LogoIcon}
              alt="eurosom Logo"
              className="mb-2"
              loading="lazy"
              style={{
                height: "80px",
                width: "120px",
              }}
            />
            <p>
              Eurosom is a pioneering technology provider specializing in cloud
              services and advanced technology solutions based in Somalia. As
              the first of its kind in the region, Eurosom offers a broad
              spectrum of services including Big Data analytics, artificial
              intelligence, ERP solutions, and cybersecurity, tailored to
              empower businesses and government entities.
            </p>
            <div className="d-flex">
              <a
                href="https://x.com/Eurosomcloud"
                className="me-3 text-primary"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size={24} />
              </a>
              <a
                href="https://www.facebook.com/Eurosomcloud"
                className="me-3 text-primary"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF size={24} />
              </a>
              <a
                href="https://www.linkedin.com/company/1011865/admin/"
                className="me-3 text-primary"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href="https://www.instagram.com/eurosomdata/"
                className="me-3 text-primary"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={24} />
              </a>

              <a
                href="https://www.tiktok.com/@eurosomc"
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size={24} />
              </a>
            </div>
          </div>
          <div className="col-md-3 mt-5 col-sm-6 mb-3">
            <h5>Eurosom Services</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  aria-label="go page"
                  to="/services/cloud-services"
                  className="mb-2"
                >
                  Cloud Services
                </Link>
              </li>
              <li>
                <Link
                  aria-label="go page"
                  to="/services/mobile-app-development"
                >
                  Mobile App Development
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/services/big-data-analytics">
                  BigData Analytics
                </Link>
              </li>
              <li>
                <Link
                  aria-label="go page"
                  to="/services/ai-ml-iot-applications"
                >
                  AI ML IoT Applications
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/services/cyber-security">
                  Cyber Security
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-6  mt-5  mb-3">
            <h5>Eurosom Solutions</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  aria-label="go page"
                  to="/solutions/cloud-infrastructure"
                  className="mb-2"
                >
                  Cloud Infrastructure
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/solutions/digital-workplace">
                  Digital Workplace
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/solutions/technology-services">
                  Technology Services
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-6  mt-5  mb-3">
            <h5>Eurosom Technology</h5>
            <ul className="list-unstyled">
              <li>
                <Link
                  aria-label="go page"
                  to="/technology/big-data-technologies"
                  className="mb-2"
                >
                  Big Data Technologies
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/technology/microsoft-powerbi">
                  Microsoft PowerBI
                </Link>
              </li>
              <li>
                <Link
                  aria-label="go page"
                  to="/technology/eurosom-data-platform"
                >
                  Eurosom Data Platform
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/technology/erp-odoo-saas">
                  ERP Odoo SaaS
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/technology/flutter-programming">
                  Flutter Programming
                </Link>
              </li>
              <li>
                <Link aria-label="go page" to="/technology/chatgpt-api">
                  Chatgpt API
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4 mb-3">
          <h5>Join Our Newsletter</h5>
          <form className="d-flex flex-md-row">
            <input
              type="email"
              className="form-control flex-grow-1 flex-shrink-0 min-w-0 rounded-0 rounded-start"
              placeholder="Enter your email"
            />
            <span className="input-group-text bg-primary rounded-0 rounded-end">
              <FaPaperPlane />
            </span>
          </form>
        </div>
        <div className="row mt-4">
          <div className="col d-flex justify-content-between align-items-center">
            <p className="mb-0">
              Copyright © 2002 Eurosom LTD , All Rights Reserved | Cloud
              Computing & Data center.
            </p>
            <div>
              <p className="mb-0">
                <Link to="privacy" className="text-white me-3">
                  Privacy Policy
                </Link>
                |
                <Link to="terms" className="text-white ms-3">
                  Terms & Conditions
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
