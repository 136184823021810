import React, { useCallback, useEffect, useState } from "react";
import handIcon from "../images/chatImage/two-icon.png";
import logoIcon from "../images/chatImage/download.png";
import WhatsappImg from "../images/whatsapp.png";
import { message } from "antd";

function ChatPopUp() {
  const [chatModal, setChatModal] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setChatModal(true);
    setAnimate(true);
  }, []);

  const handleClick = useCallback(() => {
    setChatModal(!chatModal);
  }, [chatModal]);

  const handleChange = useCallback(() => {
    const encodedMessage = encodeURIComponent(chatMessage);
    if (chatMessage) {
      const url = `https://wa.me/252619255803?text=${encodedMessage}`;
      window.open(url, "_blank");
    } else {
      message.error("Please Enter Your Message");
    }
  }, [chatMessage]);

  return (
    <>
      {chatModal && (
        <div
          className=""
          id="myModal"
          style={{
            position: "fixed",
            bottom: "100px",
            right: "20px",
            zIndex: "2000",
          }}
        >
          <div
            className=""
            style={{
              maxWidth: "340px",
              width: "340px",
            }}
          >
            <div
              className={`modal-content ${
                animate ? "animate-translate-and-fade-in" : ""
              }`}
            >
              <div
                className="modal-header d-block"
                style={{
                  backgroundColor: "#4E419D",
                }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <img
                      src={logoIcon}
                      loading="lazy"
                      alt="logoIcon"
                      style={{
                        maxWidth: "50px",
                      }}
                    />
                    <h5
                      className="modal-title text-white ms-1"
                      id="exampleModalLabel"
                    >
                      Hi There
                    </h5>
                    <img
                      src={handIcon}
                      loading="lazy"
                      alt="handIcon"
                      style={{
                        maxWidth: "20px",
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <span className="whatsapp-icon">
                      <i className="bi bi-whatsapp"></i>
                    </span>
                    <span className="insta-icon ms-2">
                      <i className="bi bi-facebook"></i>
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn chatbot-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setChatModal(false);
                  }}
                >
                  X
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="chat-window"
                  style={{ backgroundColor: "rgb(255,255,255)" }}
                >
                  <img
                    onClick={handleClick}
                    className="mt-2 chatbot-msg-icon dp-img"
                    alt="WhatsappImg"
                    src={WhatsappImg}
                    style={{
                      mixBlendMode: "multiply",
                    }}
                    loading="lazy"
                  />
                  <div className="bot-msg">
                    <div>Hi welcome to Eurosom!</div>
                    <div>We're Online 24/7</div>
                    <div>How can we help you?</div>
                  </div>
                </div>
              </div>
              <div className="message">
                <textarea
                  name=""
                  id=""
                  cols="10"
                  rows="3"
                  placeholder="Enter Your message"
                  value={chatMessage}
                  onChange={(e) => {
                    setChatMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleChange(e);
                      setChatMessage("");
                    }
                  }}
                />
                <button
                  className="btn btn-chat-bot "
                  onClick={(e) => {
                    handleChange(e);
                    setChatMessage("");
                  }}
                  style={{
                    transform: "rotate(45deg)",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#000"
                    className="bi bi-send"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        id="myModal"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "2000",
        }}
      >
        <img
          onClick={handleClick}
          className="mt-2 chatbot-msg-icon"
          alt="WhatsappImg"
          src={WhatsappImg}
          width={72}
          height={72}
          loading="lazy"
        />
      </div>
    </>
  );
}

export default ChatPopUp;
